// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-did-i-say-that-out-loud-js": () => import("./../../../src/pages/DidISayThatOutLoud.js" /* webpackChunkName: "component---src-pages-did-i-say-that-out-loud-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-just-let-me-js": () => import("./../../../src/pages/JustLetMe.js" /* webpackChunkName: "component---src-pages-just-let-me-js" */),
  "component---src-pages-miscellany-js": () => import("./../../../src/pages/miscellany.js" /* webpackChunkName: "component---src-pages-miscellany-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */)
}

